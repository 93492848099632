/***************************
 * = COULEURS
 ***************************/

$color-primary: #00a9df;
$color-primary-dark: #048eba;
$color-primary-light: #25c5f9;
$color-secondary: $color-primary;
$color-secondary-dark: $color-primary-dark;
$color-secondary-light: $color-primary-light;
$black: #513b2f;
$grey-dark: #818181;
$grey-light: #efefef;
$grey-medium: #dedede;
$color-error: #a94442;
$color-valid: #0abf74;
$color-info: #3493dc;

/***************************
 * = FONTS
 ***************************/

%font-normal {
	font-family: "gotham", sans-serif;
}

%font-bold {
	font-family: "gotham-bold", sans-serif;
	font-weight: normal; // pour éviter le bold appliqué par défaut par les navigateurs qui accentue le gras
}

%text {
	@extend %font-normal;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $black;
}

%text-bold {
	@extend %font-bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $black;
}

%text-secondary {
	@extend %font-normal;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $grey-dark;
}

%text-secondary-bold {
	@extend %font-bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $grey-dark;
}

%link {
	@extend %font-bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $color-primary;
	cursor: pointer;
	text-decoration: none;

	@include gridle_state(md) {
		&:hover {
			text-decoration: underline;
		}
	}
}

%master-button {
	text-transform: uppercase;
	border-radius: 0;
}

/***************************
 * = COMMUN
 ***************************/

$border-radius-small: 0px;
$border-radius-big: 0px;
$border-radius-top: 0 0 0 0;
$border-radius-bottom: 0 0 $border-radius-big $border-radius-big;
$header-background-color: white;
$header-border-bottom: var(--color-gray-400, $grey-medium);
$header-menu-active-color: $black;
$header-text-color: $black;
$header-text-transform: none;
$auth-header-background-color: white;
$auth-header-mobile-background-color: $auth-header-background-color;
$auth-header-text-color: $black;
$auth-header-mobile-text-color: $auth-header-text-color;

$cookie-policy-footer-background: $black;
$cookie-policy-footer-color: white;
$cookie-policy-footer-text-transform: uppercase;
$phone-number-color: $color-primary;
$common-box-shadow: none;

$hamburger-layer-color: white;

$badge-background-color: $color-primary;

$payment-banner-background-color: $color-primary;

/***************************
 * = FORM
 ***************************/

$active-color: $color-primary;
$active-color-dark: $color-primary-dark;
$active-color-light: $color-primary-light;
$input-border-color: $grey-medium;
$input-border: 1px solid var(--color-active, $active-color);
$input-border-radius: $border-radius-small;
$radio-checked-background: $grey-light;
$radio-hover-border-color: $active-color-light;
$radio-outline: 1px solid var(--color-active, $active-color);
$radio-outline-error: none;
$input-shadow-box: none;
$button-primary-background-color: $color-primary;
$button-primary-background-color-dark: $color-primary-dark;
$button-box-shadow: none;

/***************************
 * = FICHE PRODUIT
 ***************************/
$product-accommodation-text-transform: uppercase;

$product-deal-text-color: white;

$offer-mobile-background-color: $color-primary;

$bloc-contact-title-case: uppercase;
$bloc-contact-body-background: $grey-dark;
$bloc-contact-text-color: white;

$product-aside-box-shadow: none;

$datepicker-background-color: $black;

/***************************
 * = MOTEUR DE RECHERCHE
 ***************************/

$search-engine-offer-color: $black;

/***************************
 * = TUNNEL DE REZA | COMMUN
 ***************************/
$booking-bloc-title-main-background-color: $black;
$booking-item-margin: 15px;

$quotation-price-detail-toggle-button-background: $grey-dark;
$quotation-price-detail-total-background: $grey-dark;
$quotation-price-detail-total-border: 1px solid $grey-dark;
$quotation-price-detail-total-font-color: white;
$quotation-price-detail-pay-now-border: 1px solid white;
$quotation-price-detail-text-color: white;
$quotation-price-detail-total-margin-bottom: 10px;

/***************************
 * = TUNNEL DE REZA | QUOTE
 ***************************/
$booking-badge-background-color: $black;
$badge-font-color: white;
$badge-border: none;
$badge-border-radius: 0;

$quotation-filter-sort-background: white;

$flight-filter-button-background-color: $grey-light;
$flight-filter-button-color: $black;
$flight-filter-button-border-radius: $border-radius-big;
$flight-filter-background-color: $active-color;

$quotation-luggage-included-border-radius: $border-radius-small;
$quotation-luggage-included-outline: unset;

/***************************
 * = TUNNEL DE REZA | PAYMENT
 ***************************/
$insurances-radio-second-color: $black;

/***************************
 * = TUNNEL DE REZA | CONFIRMATION
 ***************************/
$booking-confirmation-reference-color: $color-primary;

/***************************
 * = LISTING
 ***************************/
$product-cta-background-color: $button-primary-background-color;
$product-cta-border: 1px solid $button-primary-background-color;
$product-cta-button-radius: 5px;
$product-deal-public-price-line-through-color: $black;
$product-intemporelle-color: $color-primary;
$listing-actionbar-title-display-on-mobile: block;
$listings-product-background-color: $grey-light;
$colored-product-badge-background-color: $color-primary;
$colored-product-badge-text-transform: uppercase;
$colored-product-badge-border-radius: 5px;
$merchandising-simple-width: 100%;
$listing-time-background-color: $color-primary;

/***************************
 * = PLACEHOLDERS
 ***************************/

%booking-title {
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	box-sizing: border-box;
	text-align: center;
	min-height: 40px;
	padding: 10px 0;
}

%input-label-touched {
	position: absolute;
	top: 0;
	left: 5px;
	font-size: 1rem;
	color: var(--color-active, $active-color);
	background: none;
	padding-right: 10px;
	padding-left: 10px;
}

$auth-background-color: #27c8ee;
$listing-header-filter-max-width: none;
$listing-header-sticky-container-box-shadow: none;
$product-filter-header-box-shadow: none;
$products-filter-selected-border: none;
$products-master-filter-box-shadow: unset;
$aside-product-filters-menu-filters-title-height: 40px;

$marketing-slide-small-logo: url("/static/icons/icon-marketing-slide-small-logo-af.svg");
$marketing-slide-small-1: url("/static/icons/icon-marketing-slide-small-af-1.svg");
